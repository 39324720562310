<template>
  <div name="submotivos">
    <v-row>
      <v-col cols="12">
        <Crud
          title="Gestão de Submotivos"
          buttonAddItemText="Novo submotivo"
          searchLabelText="Pesquisar na lista"
          :searchTextField.sync="searchTextField"
          :formTitle="nameForm"
          :callBackModalCancelar="fechar"
          :dialog.sync="dialog"
          :callBackModalSalvar="salvar"
          :dialogDelete.sync="dialogDelete"
        >
          <template slot="data-table">
            <v-data-table
              :headers="headers"
              :items="submotivos"
              :search="searchTextField"
              items-per-page-text="Linhas por página"
              no-results-text="Nenhum registro encontrado"
              no-data-text="Nenhum registro encontrado"
              mobile-breakpoint="0"
              :loading="loadingTabela"
            >
              <!-- ACTIONS -->
              <template v-slot:[`item.actions`]="{ item }">
                <v-menu bottom left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item @click="toggleModalLateral(item)">
                      <v-list-item-title>
                        <v-icon size="20" class="me-2"> mdi-eye </v-icon>
                        <span>Visualizar</span>
                      </v-list-item-title>
                    </v-list-item>

                    <v-list-item @click="editar(item)">
                      <v-list-item-title>
                        <v-icon size="20" class="me-2"> mdi-pencil </v-icon>
                        <span>Editar</span>
                      </v-list-item-title>
                    </v-list-item>

                    <v-list-item @click="remover(item, true)">
                      <v-list-item-title>
                        <v-icon size="20" class="me-2"> mdi-delete </v-icon>
                        <span>Apagar</span>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
              <!-- CREATED_AT -->
              <template v-slot:[`item.created_at`]="{ item }">
                <span>{{ item.created_at | dateTimeFormatBr() }}</span>
              </template>
              <template v-slot:[`item.updated_at`]="{ item }">
                <span>{{ item.updated_at | dateTimeFormatBr() }}</span>
              </template>
              <!-- STATUS -->
              <template v-slot:[`item.status`]="{ item }">
                <v-chip
                  :color="getColor(item.status)"
                  :class="`${getColor(item.status)}--text`"
                  class="v-chip-light-bg font-weight-semibold text-capitalize"
                >
                  {{
                    item.status == "A" || item.status == "Ativo"
                      ? "Ativo"
                      : "Inativo"
                  }}
                </v-chip>
              </template>
            </v-data-table>
          </template>
          <!-- ------------- MODAL DE DELETE --------------------------------------------- -->
          <template slot="modalDelete/titleModal">Excluir o registro?</template>
          <template slot="modalDelete/contentModal">
            <p>
              Atenção! Você está prestes a realizar uma ação que não pode ser
              desfeita.
            </p>
            <p>Você realmente deseja excluir esse registro?</p>
          </template>
          <template slot="modalDelete/actionsModal">
            <v-btn depressed @click="dialogDelete = false">Fechar</v-btn>
            <v-btn color="error" @click="remover()">Excluir</v-btn>
            <v-spacer class="d-none d-md-block"></v-spacer>
          </template>
          <!-- ------------- MODAL DE CADASTRO --------------------------------------------- -->
          <template slot="contentModalCrud">
            <v-container fluid>
              <v-row class="ma-3" wrap>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    ref="descricao"
                    label="Descrição"
                    placeholder="Ex.: Já utilizo um sistema"
                    required
                    outlined
                    dense
                    v-model="submotivo.descricao"
                    data-vv-as="descricao"
                    persistent-hint
                    :hint="errors.first('descricao')"
                    :error="errors.collect('descricao').length ? true : false"
                    v-validate="'required|min:3'"
                    data-vv-validate-on="change"
                    id="descricao"
                    name="descricao"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6" v-if="editedIndex">
                  <v-select
                    ref="status"
                    label="Status"
                    placeholder="Selecione um status"
                    :items="SelectItensStatus"
                    dense
                    outlined
                    v-model="submotivo.status"
                    value="A"
                  ></v-select>
                </v-col>
              </v-row>
            </v-container>
          </template>
        </Crud>
      </v-col>
    </v-row>
    <ModalLateral
      :show="modalLateral.show"
      :hide="toggleModalLateral"
      :title="modalLateral.title"
      :headers="headerModalLateral"
      :item="modalLateral.item"
    >
      <template v-slot:[`item.status`]="{ item }">
        <span>{{ item.status == "A" ? "Ativo" : "Inativo" }}</span>
      </template>
      <template v-slot:[`item.created_at`]="{ item }">
        <span>
          {{ item.created_at | dateFormatBr() }}
          {{ item.created_at | timeFormatBr() }}
        </span>
      </template>
    </ModalLateral>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate color="#001325" size="75">
        <img :src="logotipo" height="45" alt="" />
      </v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import logo from "@/assets/images/logos/legiio.png";
import Crud from "@/components/Crud.vue";
import ModalLateral from "@/components/ModalLateral.vue";

export default {
  name: "submotivos",
  inject: ["$validator"],
  components: { Crud, ModalLateral },
  data: () => ({
    logotipo: logo,
    loadingTabela: false,
    nameForm: "Novo submotivo",
    modalLateral: {
      show: false,
      item: [],
      title: "",
    },
    submotivo: {
      descricao: "",
      status: "",
    },
    SelectItensStatus: [
      { text: "Ativo", value: "A" },
      { text: "Inativo", value: "I" },
    ],
    searchTextField: "",
    isUpdating: false,
    item: [],
    dialogDelete: false,
    dialog: false,
    editedIndex: false,
    headers: [
      { text: "Descrição", align: "start", value: "descricao" },
      { text: "Data de criação", value: "created_at" },
      { text: "Status", value: "status" },
      { text: "Ações", align: "end", value: "actions", sortable: false },
    ],
    headerModalLateral: [
      { text: "Descrição", align: "start", value: "descricao" },
      { text: "Data de criação", value: "created_at" },
      { text: "Status", value: "status" },
    ],
  }),

  created() {
    this.$store.dispatch("submotivos/setSubmotivos");
  },

  computed: {
    submotivos: {
      get() {
        return this.$store.getters["submotivos/getSubmotivos"];
      },
    },
    loading: {
      get() {
        return this.$store.getters["submotivos/getLoading"];
      },
      set(value) {
        return value;
      },
    },
    computedHeaders() {
      return this.headers.filter(
        (h) => !h.hide || !this.$vuetify.breakpoint[h.hide]
      );
    },
  },

  methods: {
    //Modal Lateral
    handleClickRow(value) {
      this.toggleModalLateral(value);
    },
    toggleModalLateral(item) {
      this.modalLateral.title = this.modalLateral.show ? "" : item.nome;
      this.modalLateral.item = this.modalLateral.show ? [] : item;
      this.modalLateral.show = this.modalLateral.show ? false : true;
    },
    // O salvar so está salvando, não está editando
    salvar() {
      //this.dialog = false;
      this.$validator.validateAll().then((result) => {
        if (result === true) {
          //this.loading = true;
          this.loadingTabela = true;

          if (this.editedIndex == false) {
            this.$store
              .dispatch("submotivos/salvarSubmotivos", this.submotivo)
              .then((data) => {
                if (data != "NAO_AUTORIZADO") {
                  this.$store.dispatch("snackBarModule/openSnackBar", {
                    color: "success",
                    timeout: 3000,
                    text: "Item registrado com sucesso",
                  });
                }
              })
              .catch(() => {
                this.$store.dispatch("snackBarModule/openSnackBar", {
                  color: "error",
                  timeout: 3000,
                  text: "Houve um erro ao cadastrar motivo.",
                });
              })
              .finally(() => {
                this.loadingTabela = false;
              });
          } else {
            this.$store
              .dispatch("submotivos/atualizarSubmotivo", this.submotivo)
              .then((data) => {
                if (data != "NAO_AUTORIZADO") {
                  this.$store.dispatch("snackBarModule/openSnackBar", {
                    color: "success",
                    timeout: 3000,
                    text: "Item atualizado com sucesso",
                  });
                }
              })
              .catch(() => {
                this.$store.dispatch("snackBarModule/openSnackBar", {
                  color: "error",
                  timeout: 3000,
                  text: "Houve um erro ao cadastrar motivo.",
                });
              })
              .finally(() => {
                this.loadingTabela = false;
              });
          }

          return this.fechar();
        }
      });

      // this.$validator.validateAll().then(result => {
      // });
    },
    editar(item) {
      this.nameForm = "Editar submotivo: " + item.descricao;
      this.submotivo = item;
      this.editedIndex = true;
      this.dialog = true;
    },
    fechar() {
      this.dialog = false;
      this.editedIndex = false;
      this.nameForm = "Novo submotivo";
      this.submotivo = {
        descricao: "",
        status: "",
      };
      this.loading = false;
    },
    remover(item, openModal = false) {
      if (openModal) {
        this.item = item;
        this.dialogDelete = true;
      } else {
        this.loadingTable = true;
        this.$store
          .dispatch("submotivos/deletarSubmotivo", this.item.uuid)
          .then((response) => {
            if (response.data === true) {
              this.$store.dispatch("snackBarModule/openSnackBar", {
                color: "success",
                timeout: 3000,
                text: "Registro removido.",
              });
              this.item = {};
            }
          })
          .catch(() => {
            this.$store.dispatch("snackBarModule/openSnackBar", {
              color: "error",
              timeout: 3000,
              text: "Não foi possivel remover o registro.",
            });
          })
          .finally(() => {
            this.loadingTable = false;
            this.dialogDelete = false;
          });
      }
    },
    //DATATABLE COSTUMIZATION
    getColor(status) {
      if (status == "I" || status == "Inativo") return "error";
      if (status == "A" || status == "Ativo") return "success";

      return "primary";
    },
  },
};
</script>

<style scoped></style>
